import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Pages/Home';
import Footer from './Components/Footer/Footer';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import Hoodies from './Pages/Hoodies';
import About from './Pages/About';
import Basketball from './Pages/Basketball';
import Tshirt from './Pages/Tshirt';
import Soccer from './Pages/Soccer';
import Polo from './Pages/Polo';
import Cricket from './Pages/Cricket';
import Headwear from './Pages/Headwear';
import AFL from './Pages/AFL';
import SchoolUniform from './Pages/SchoolUniform';
import Netball from './Pages/Netball';
import FAQ from './Pages/FAQ';
import Price from './Pages/Price';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import TC from './Pages/TC';
import "./index.css"

function App() {
  return (
    <div>
      <BrowserRouter >
        <Navbar />
        <ScrollToTop/>
        <Routes>
          <Route path='/' element = {<Home />}/>
          <Route path='/contact' element = {<Contact />} />
          <Route path='/services' element = {<Services/>} />
          <Route path='/hoodies' element = {<Hoodies/>} />
          <Route path='/basketball' element = {<Basketball/>} />
          <Route path='/tshirt' element = {<Tshirt/>} />
          <Route path='/soccer' element = {<Soccer/>} />
          <Route path='/polo' element = {<Polo/>} />
          <Route path='/cricket' element = {<Cricket/>} />
          <Route path='/headwear' element = {<Headwear/>} />
          <Route path='/afl' element = {<AFL/>} />
          <Route path='/school' element = {<SchoolUniform/>} />
          <Route path='/netball' element = {<Netball/>} />
          <Route path='/about' element = {<About/>} />
          <Route path='/FAQ' element = {<FAQ/>} />
          <Route path='/price' element = {<Price/>} />
          <Route path='/tc' element = {<TC/>} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
