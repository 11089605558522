import React from 'react'
import "./OurStory.css"
import craft from "../Assets/craftImage.png"
import story from "../Assets/storyImage.png"

const OurStory = () => {
  return (
    <div className='our'>
        <section  className='ourStory'>
            <div className="sectionImage">
                <img src={craft} alt="" />
            </div>
            <div className="sectionContent">
                <span className='sectionHeading'>OUR STORY</span>
                <span className='sectionText'>OZLOOMS began with a simple idea: to offer high-quality, custom-made clothing that fits perfectly and looks stunning and without breaking your bank. Our founders saw a gap in the market for truly personalized fashion and decided to combine traditional tailoring techniques with modern technology to fill this void.</span>
            </div>
        </section>
        <section  className='ourCraft'>
            <div className="sectionContent">
                <span className='sectionHeading'>OUR CRAFT</span>
                <span className='sectionText text2'>At the heart of OZLOOMS  is a commitment to craftsmanship and a globally competitive pricing . Every piece of clothing we create is meticulously handcrafted using the finest materials sourced from around the world. Our tailors and technicians bring decades of experience to every stitch, ensuring that each garment not only fits flawlessly but also feels luxurious to wear.</span>
            </div>
            <div className="sectionImage">
                <img src={story} alt="" />
            </div>
        </section>
    </div>
  )
}

export default OurStory;