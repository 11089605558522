import React from 'react'
import "./Capacity.css"
import capacity1 from "../Assets/capacity1.png"
import capacity2 from "../Assets/capacity2.png"
import capacity3 from "../Assets/capacity3.png"

const Capacity = () => {
  return (
    <div className='capacity'>
        <div className="capacityHeading">
            <span className="capacityTitle">Capacity and Facilities</span>
        </div>
        <div className="capacityContent">
            <div className="capacityCard">
                <div className="cardImage">
                    <img src={capacity1} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardHeading">Factory Size</span>
                    <span className="cardText">15000 Sq Ft of state-of-the-art manufacturing space equipped with advanced machinery</span>
                </div>
            </div>
            <div className="capacityCard">
                <div className="cardImage">
                    <img src={capacity2} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardHeading">Production Capacity</span>
                    <span className="cardText">Capable of producing up to 5000 custom garments per month.</span>
                </div>
            </div>
            <div className="capacityCard">
                <div className="cardImage">
                    <img src={capacity3} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardHeading">Quality Assurance</span>
                    <span className="cardText">Dedicated quality control team ensuring each piece meets stringent standards before delivery.</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Capacity