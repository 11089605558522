import React from 'react'
import "./WhyPrinting.css"

const WhyPrinting = () => {
  return (
    <div className='why-printing'>
      <div className="why-heading">
        <span>Why choose our printing <span className='color-change'>services</span>?</span>
      </div>
      <div className="why-printing-content">
        <div className="printing-card card1">
            <div className="printing-card-info">
                <span className='printing-card-title'>State-of-the-Art Technology</span>
                <span className="printing-card-tagline">We use the latest printing equipment and techniques to ensure high-quality results.</span>
            </div>
        </div>
        <div className="printing-card card2">
            <div className="printing-card-info">
                <span className='printing-card-title'>Custom Solutions</span>
                <span className="printing-card-tagline">Whether you need a single custom piece or a large batch, we tailor our services to meet your specific needs.</span>
            </div>
        </div>
        <div className="printing-card card3">
            <div className="printing-card-info">
                <span className='printing-card-title'>Expert Team</span>
                <span className="printing-card-tagline">Our experienced designers and technicians are dedicated to delivering perfect prints every time.</span>
            </div>
        </div>
        <div className="printing-card card4">
            <div className="printing-card-info">
                <span className='printing-card-title'>Sustainable Practices</span>
                <span className="printing-card-tagline">We offer eco-friendly printing options to reduce environmental impact.</span>
            </div>
        </div>
      </div>
    </div>
  )
}

export default WhyPrinting
