const faqData = {
    question1: {
        question: "What is OZLOOMS?",
        answer: "OZLOOMS is a custom garment-making company specializing in Sportswear, bespoke tailoring for suits, dresses, casual wear, corporate uniforms, and more. We offer a wide range of personalized services to meet your unique fashion needs."
    },
    question2: {
        question: "Where are you located?",
        answer: "We are located in Adelaide, South Australia and Our factory is strategically located in India and Taiwan, known for its rich tradition in textile manufacturing. This location allows us to source high-quality materials and employ skilled artisans to create exceptional garments."
    },
    question3: {
        question: "What types of custom garments do you offer?",
        answer: "The turnaround time varies depending on the complexity of the garment and our current workload. Typically, custom garments take between 4-6 weeks from consultation to final fitting and final approval of the tech sheet."
    },
    question4: {
        question: " What types of printing services do you offer?",
        answer: "We offer screen printing, digital printing (DTG), sublimation printing, heat transfer printing, foil printing, and discharge printing. Each method is suited for different types of garments and design requirements"
    },
    question5: {
        question: "Can I get a sample before placing a large order?",
        answer: "Yes, we offer prototyping and sample development services. This allows you to see and approve a sample before proceeding with a bulk order."
    },
    question6: {
        question: "How do you ensure a perfect fit?",
        answer: "We take detailed measurements during the initial consultation and offer multiple fittings to ensure your garment fits perfectly. Our precision fitting process includes adjustments as needed until you are completely satisfied."
    },
    question7: {
        question: "Do you offer alteration services for existing garments?",
        answer: " No, we do not  provide professional alteration services. We only offer new garment constructions"
    },
    question8: {
        question:"Do you provide custom uniforms for businesses?",
        answer: "Yes, we specialize in designing and producing custom uniforms for businesses and organizations, ensuring a professional and cohesive look for your team."
    },
    question9: {
        question:"Is there a minimum order quantity for bulk or wholesale orders?",
        answer: " The minimum order quantity varies depending on the type of garment and printing method. Please contact us for specific details regarding your order."
    },
    question10: {
        question: "Are your materials and production methods environmentally friendly?",
        answer: "We are committed to sustainability and offer eco-friendly fabrics and responsible production practices to minimize our environmental impact."
    },
    question11: {
        question: "How do you contribute to sustainable fashion?",
        answer: "We source sustainable materials, use eco-friendly production methods, and create durable garments designed to last, reducing the need for frequent replacements and minimizing waste."
    },
    question12: {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods including credit/debit cards, bank transfers, and online payment platforms. Please contact us for more details."
    },
    question13: {
        question: "Do you offer international shipping?",
        answer: "Yes, we offer international shipping. We work with reliable operators to offer shipping to all over the world. Shipping costs and delivery times vary based on your location. Please contact us for a shipping quote and estimated delivery time."
    },
    question14: {
        question: "How can I contact customer support?",
        answer: "You can reach our customer support team via phone: +61450590000, email : Info@ozlooms.com.au, or through the contact form on our website. We are here to assist you with any questions or concerns you may have."
    },
    question15: {
        question: "What is your return policy?",
        answer: "Due to the custom nature of our garments, we do not accept change of mind returns. We will happily replace any faulty garments if it is brought to our attention within 14 days of purchase and unused. However, we stand by the quality of our work and will make any necessary adjustments to ensure your satisfaction. Please contact us if you have any issues with your order."
    }
};

export default faqData;