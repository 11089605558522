import React, { useState } from "react";
import './ContactForm.css';
import axios from 'axios';

const ContactForm = () => {
    const [fileName, setFileName] = useState(""); 

    
    const handleFileChange = (e) => {
        const file = e.target.files[0]; 
        if (file) {
            setFileName(file.name); 
        }
    };
    
    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); 

        //Create form data Object
        const formData = new FormData();
        formData.append('firstName', e.target.firstName.value);
        formData.append('lastName', e.target.lastName.value);
        formData.append('club', e.target.club.value);
        formData.append('location', e.target.location.value);
        formData.append('email', e.target.email.value);
        formData.append('phone', e.target.phone.value)
        formData.append('productType', e.target.productType.value);
        formData.append('quantity', e.target.quantity.value);
        formData.append('customInput', e.target.customInput.value);
        if (e.target.fileInput.files[0]){
            formData.append('attachment', e.target.fileInput.files[0]);
        }

        try{
            await axios.post('http://www.testing.quindl.com/submit-form', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            alert("Form submitted Successfully")
        } catch (error) {
            console.error('Error submitting form:', error);
            alert(error);
        }
    };

    return (
        <div className="contact_container" id="contact_container">
            <div className="contact_address">
                <div className="aus_address">
                    <div className="aus_heading">
                        <div className="block"></div>
                        <span>AUSTRALIA</span>
                    </div>
                    <div className="aus_add">
                        <span>MARS Sports Centre</span>
                        <span>43-63 Lower Portrush Rd, Marden</span>
                        <span>South Australia 5070</span>
                        <span>(+61)450590000</span>
                        <span>info@ozlooms.com.au</span>
                    </div>
                </div>
                <div className="ind_address">
                    <div className="ind_heading">
                        <div className="block"></div>
                        <span>INDIA</span>
                    </div>
                    <div className="ind_add">
                        <span>Gods own apparels LLP</span>
                        <span>Star complex, court road, Taliparamba</span>
                        <span>Kannur, Kerala-670141</span>
                        <span>(+91)9745654555</span>
                    </div>
                </div>
            </div>
            <div className="contact_form">
                <form onSubmit={handleSubmit}>
                    <div className="form_heading">
                        <span>Reach out to us</span>
                    </div>
                    <div className="form">
                        <div className="row">
                            <div className="div">
                                <label htmlFor="firstName">First Name</label>
                                <input id="firstName" name="firstName" type="text" required/>
                            </div>
                            <div>
                                <label htmlFor="lastName">Last Name</label>
                                <input id="lastName" name="lastName" type="text" required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="div">
                                <label htmlFor="club">Club/School/Brand/Organisation</label>
                                <input id="club" name="club" type="text" required/>
                            </div>
                            <div>
                                <label htmlFor="location">Location</label>
                                <input id="location" name="location" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="div">
                                <label htmlFor="email">Email Address</label>
                                <input id="email" name="email" type="text" required/>
                            </div>
                            <div>
                                <label htmlFor="phone">Phone</label>
                                <input id="phone" name="phone" type="text" required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="div">
                                <label htmlFor="productType">Type of Product</label>
                                <select name="productType" id="productType">
                                    <option value="" disabled defaultValue="">Choose one</option>
                                    <option value="sports">Sports</option>
                                    <option value="casual">Casual Wear</option>
                                    <option value="uniform">Uniform</option>
                                    <option value="accessories">Accessories</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="quantity">Quantity</label>
                                <input id="quantity" name="quantity" type="number" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="text-row">
                                <label htmlFor="customInput">Let us know your goals for your custom uniform/apparel</label>
                                <textarea name="customInput" id="customInput" cols="30" rows="10"></textarea>
                                <div className="upload">
                                    <span>Upload Your Logos & Artwork</span>
                                    <label htmlFor="fileInput">Select Files</label>
                                    <input name="fileInput" id="fileInput" type="file" onChange={handleFileChange} />
                                    <p>{fileName}</p> {/* Display file name */}
                                </div>
                                <button type="submit">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;
