import React from 'react'
import "./CustomTailoring.css"

const CustomTailoring = () => {
  return (
    <div className='customTailoring'> 
      <section className='sectionHeading'>
        <span className='heading'>Custom Tailoring</span>
        <span className='tagline'>Perfect Fit, Unmatched Style.</span>
        <button>Get you outfit customized!</button>
      </section>
      <section className='cardContainer'>
        <div className="card card1">
          <div className="cardContent">
            <span className='cardHeading'>Sports Garments</span>
            <span className='cardTagline'>Custom made sports garments for your clubs or a team</span>
          </div>
        </div>
        <div className="card card2">
          <div className="cardContent">
            <span className='cardHeading'>Bespoke Suits & Tuxedos</span>
            <span className='cardTagline'>Tailored suits and tuxedos for men and women, crafted to fit perfectly and made from premium fabrics.</span>
          </div>
        </div>
        <div className="card card3">
        <div className="cardContent">
          <span className='cardHeading'>Custom Dresses & Gowns</span>
          <span className='cardTagline'>Elegant evening gowns, cocktail, and wedding dresses designed to reflect your personal style.</span>
        </div>
        </div>
        <div className="card card4">
        <div className="cardContent">
          <span className='cardHeading'>Casual Wear</span>
          <span className='cardTagline'>Personalized shirts, blouses, trousers, and casual outfits tailored to your measurements and style preferences.</span>
        </div>  
        </div>
      </section>
    </div>
  )
}

export default CustomTailoring
