import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img1 from '../Assets/banner_1.jpg';
import imgMobile1 from "../Assets/banner_1_mobile.jpg";
import img2 from '../Assets/banner_2.jpg';
import imgMobile2 from "../Assets/banner_2_mobile.jpg";
import img3 from '../Assets/banner_3.jpg';
import imgMobile3 from "../Assets/banner_3_mobile.jpg";
import img4 from '../Assets/banner_4.jpg';
import imgMobile4 from "../Assets/banner_4_mobile.jpg";
import img5 from '../Assets/banner_5.jpg';
import imgMobile5 from "../Assets/banner_5_mobile.jpg";
import { Link } from 'react-router-dom';
import "./BannerCarousel.css";

const CarouselPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 700);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='bannerCarousel'>
      <Carousel>
        <Carousel.Item>
          <img
            style={{ height: '100vh', objectFit: "cover" }}
            className="d-block w-100"
            src={isMobile ? imgMobile1 : img1}
            alt="First slide"
          />
          <Carousel.Caption className='carouselCaption'>
            <div className="carouselContent">
              <span className='bannerHeading'>
                CUSTOM POLOS
              </span>
              <span className='bannerText'>
                ELEVATE YOUR CASUAL STYLE:<br/>Personalized Polo T-shirts tailored just for you
              </span>
              <Link to="/contact">
                <button style={{background:'#cb901a'}}>
                  CONNECT WITH US
                </button>
            </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: '100vh', objectFit: "cover" }}
            className="d-block w-100"
            src={isMobile ? imgMobile2 : img2}
            alt="First slide"
          />
          <Carousel.Caption className='carouselCaption'>
            <div className="carouselContent">
              <span className='bannerHeading'>
                CUSTOM HOODIES
              </span>
              <span className='bannerText'>
                WRAP YOURSELF IN PERSONALIZED COMFORT:<br/>Custom-made hoodies just for you
              </span>
              <span className="bannerText">
                
              </span>
              <Link to="/contact">
                <button style={{background:'#31406b'}}>
                  GET IN TOUCH
                </button>
            </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: '100vh', objectFit: "cover" }}
            className="d-block w-100"
            src={isMobile ? imgMobile3 : img3}
            alt="First slide"
          />
          <Carousel.Caption className='carouselCaption'>
            <div className="carouselContent">
              <span className='bannerHeading'>
                PERSONALIZED T-SHIRT
              </span>
              <span className='bannerText'>
                STAND OUT ON THE FIELD:<br/>Your Custom Jersey Awaits, Designed for Victory
              </span>
              <Link to="/contact">
                <button style={{background:'#4b92b0'}}>
                  REACH OUT
                </button>
            </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: '100vh', objectFit: "cover" }}
            className="d-block w-100"
            src={isMobile ? imgMobile4 : img4}
            alt="First slide"
          />
          <Carousel.Caption className='carouselCaption'>
            <div className="carouselContent">
              <span className='bannerHeading'>
                SPORTS WEAR
              </span>
              <span className='bannerText'>
                GEAR UP FOR SUCCESS:<br/>Personalized sports wear tailored for peak performance
              </span>
              <Link to="/contact">
                <button style={{background:'#d47438'}}>
                  CONTACT NOW
                </button>
            </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: '100vh', objectFit: "cover" }}
            className="d-block w-100"
            src={isMobile ? imgMobile5 : img5}
            alt="First slide"
          />
          <Carousel.Caption className='carouselCaption'>
            <div className="carouselContent">
              <span className='bannerHeading'>
                UNIQUE APPARELS
              </span>
              <span className='bannerText'>
                YOUR STYLE, YOUR WAY:<br/>Custom apparel solutions for every occasion 
              </span>
              <Link to="/contact">
                <button style={{background:'#6db4ac'}}>
                  CONTACT TODAY
                </button>
            </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselPage;
