import React from 'react';
import { Accordion } from "rsuite";
import faqData from '../Components/Assets/FAQDATA';
import "./styles/faq.css";

const FAQ = () => (
    <div className="faq">
        <Accordion defaultActiveKey={1} bordered>
            {Object.entries(faqData).map(([key, faq], index) => (
                <Accordion.Panel header={faq.question} eventKey={index + 1} key={key}>
                    {faq.answer}
                </Accordion.Panel>
            ))}
        </Accordion>
    </div>
);

export default FAQ;
