import React from "react";
import './Hero.css';


const Hero = (props) => {
    return(
        <div className="hero">
            <img className="hero_landscape" src={props.landscape_image} alt="" />
            <img className="hero_potrait" src={props.potrait_image} alt="" />
            <a href="#contact_container" className="toForm">Connect with us</a>
        </div>
    );
};

export default Hero;