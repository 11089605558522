import React from 'react'
import "./CustomPrinting.css"


const CustomPrinting = () => {
  return (
    <div className='custom-printing'>
      <div className="printing-heading">
        <span className="printing-title">CUSTOM PRINTING</span>
        <span className="printing-tagline">Print your vision: Custom design with unmatched precision</span>
      </div>
      <div className="printing-content">
        <div className="card card1">
            <div className="card-info">
                <h3>Screen Printing</h3>
                <ul>
                    <li><b>Overview:</b> Ideal for bulk orders, screen printing involves creating a stencil (or screen) and using it to apply layers of ink on the fabric.</li>
                    <li><b>Best For:</b> Large quantities, simple designs, and vibrant colors on dark fabrics.</li>
                    <li><b>Advantages:</b> Cost-effective for large orders, durable prints, vivid colors.</li>
                </ul>
            </div>
        </div>
        <div className="card card2">
            <div className="card-info">
                <h3>Digital Printing</h3>
                <ul>
                    <li><b>Overview:</b> Also known as direct-to-garment (DTG) printing, this method uses inkjet technology to print designs directly onto the fabric.</li>
                    <li><b>Best For:</b> Small orders, intricate designs, and photo-quality prints.</li>
                    <li><b>Advantages:</b> High-resolution prints, no setup costs, suitable for detailed artwork.</li>
                </ul>
            </div>
        </div>
        <div className="card card3">
            <div className="card-info">
                <h3>Sublimation Printing</h3>
                <ul>
                    <li><b>Overview:</b> Sublimation printing involves transferring dye onto fabrics using heat, resulting in vibrant, all-over prints.</li>
                    <li><b>Best For:</b> Polyester fabrics, sportswear, and items that require full-coverage prints.</li>
                    <li><b>Advantages:</b> Long-lasting prints, vibrant colors, the design becomes part of the fabric.</li>
                </ul>
            </div>
        </div>
        <div className="card card4">
            <div className="card-info">
                <h3>Embroidery</h3>
                <ul>
                    <li><b>Overview:</b> Although not a printing method, embroidery involves stitching designs onto fabric using thread. It's often combined with printed elements for added texture and durability.</li>
                    <li><b>Best For:</b> Corporate logos, monograms, patches, and decorative elements on apparel.</li>
                    <li><b>Advantages:</b> High-quality, professional appearance, durable, and suitable for a variety of fabrics.</li>
                </ul>
            </div>
        </div>
        <div className="card card5">
            <div className="card-info">
                <h3>Heat Transfer Printing</h3>
                <ul>
                    <li><b>Overview:</b> This method uses heat to transfer vinyl or printed designs onto the fabric. It includes techniques like heat transfer vinyl (HTV) and digital heat transfers.</li>
                    <li><b>Best For:</b> Custom T-shirts, sports jerseys, and promotional items.</li>
                    <li><b>Advantages:</b> Good for small runs, versatile, allows for customization of names and numbers.</li>
                </ul>
            </div>
        </div>
        <div className="card card6">
            <div className="card-info">
                <h3>Foil Printing</h3>
                <ul>
                    <li><b>Overview:</b> Foil printing applies metallic foils to fabric using heat and adhesive, creating a shiny, reflective surface.</li>
                    <li><b>Best For:</b> Fashion garments, accessories, and promotional products.</li>
                    <li><b>Advantages:</b> Eye-catching, luxurious appearance, and available in various colors.</li>
                </ul>
            </div>
        </div>
        <div className="card card7">
            <div className="card-info">
                <h3>Discharge Printing</h3>
                <ul>
                    <li><b>Overview:</b> This process involves removing the dye from the fabric and replacing it with another color, resulting in a soft, vintage look.</li>
                    <li><b>Best For:</b> Fashion T-shirts, casual wear, and designs requiring a soft hand feel.</li>
                    <li><b>Advantages:</b> Soft feel, vibrant prints on dark fabrics, unique vintage effect.</li>
                </ul>
            </div>
        </div>
        <div className="card card8">
            <div className="card-info">
                <h3>All-Over Printing</h3>
                <ul>
                    <li><b>Overview:</b> All-over printing covers the entire garment with a design, often using sublimation or digital printing techniques.</li>
                    <li><b>Best For:</b> Activewear, fashion items, and custom apparel requiring comprehensive design coverage.</li>
                    <li><b>Advantages:</b> Complete design freedom, vibrant colors, and seamless patterns.</li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CustomPrinting