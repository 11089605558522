import React from 'react'
import "./AboutSustain.css"

const AboutSustain = () => {
  return (
    <div className='aboutSustain'>
        <div className="sustainContainer">
            <span className="aboutSustainHeading"><span className="colorChange">Sustainable</span> fashion</span>
            <span className="aboutSustainText">We are also committed to sustainability. By creating custom pieces made to last, we help reduce the waste associated with fast fashion. We carefully select eco-friendly fabrics and employ responsible production methods to minimize our environmental impact.</span>
            <button>Get a quote</button>
        </div>
    </div>
  )
}

export default AboutSustain