import React from 'react'
import "./Advantages.css"
import Advantages1 from "../Assets/advantages1.png"
import Advantages2 from "../Assets/advantages2.png"
import Advantages3 from "../Assets/advantages3.png"
import Advantages4 from "../Assets/advantages4.png"
import Advantages5 from "../Assets/advantages5.png"

const Advantages = () => {
  return (
    <div className='advantages'>
        <div className="advantagesHeading">
            <span className="advantagesTitle">Competitive <span className="colorChange">Advantages</span></span>
        </div>
        <div className="advantagesContent">
            <div className="advantagesCard">
                <div className="logo">
                    <img src={Advantages1} alt="" />
                </div>
                <div className="content">
                    <span className="contentHeading">Strategic Location</span>
                    <span className="contentText">Proximity to top-quality fabric suppliers and transportation hubs for efficient logistics and reduced lead times.</span>
                </div>
            </div>
            <div className="advantagesCard">
                <div className="logo">
                    <img src={Advantages2} alt="" />
                </div>
                <div className="content">
                    <span className="contentHeading">Skilled Workforce</span>
                    <span className="contentText">Experienced artisans and tailors dedicated to excellence in garment construction.</span>
                </div>
            </div>
            <div className="advantagesCard">
                <div className="logo">
                    <img src={Advantages3} alt="" />
                </div>
                <div className="content">
                    <span className="contentHeading">Customer-Centric Approach</span>
                    <span className="contentText">Personalized service and attention to detail, ensuring a seamless and enjoyable experience for every client.</span>
                </div>
            </div>
            <div className="advantagesCard">
                <div className="logo">
                    <img src={Advantages4} alt="" />
                </div>
                <div className="content">
                    <span className="contentHeading">Technological Integration:</span>
                    <span className="contentText">Utilization of modern technology in design, measurement, and production processes for enhanced precision and efficiency.</span>
                </div>
            </div>
            <div className="advantagesCard">
                <div className="logo">
                    <img src={Advantages5} alt="" />
                </div>
                <div className="content">
                    <span className="contentHeading">Sustainability Commitment:</span>
                    <span className="contentText">Eco-friendly materials and practices that align with global sustainability goals.</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Advantages