import React from 'react'
import landscape_banner from "../Components/Assets/AFLBanner.jpg"
import potrait_banner from "../Components/Assets/AFLBanner_Mobile.jpg"
import { Link } from 'react-router-dom'
import Hero from '../Components/Hero/Hero'

const AFL = () => {
  return (
    <div>
      <Hero landscape_image = {landscape_banner} potrait_image = {potrait_banner}/>
      <div className="productContent">
        {/*Redirect button*/}
        <div className="button_container">
          <button>Get your own custom Basketball jerseys!</button>
        </div>

        {/*On-Court*/}
        <div className="content_heading">
          <span>GAME DAY</span>
        </div>
        <hr />
        <div className="content_tagline">
          <div className="bar"></div>
          <span>It's more than a game</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>Sampling prior to production</span></li>
            <li><span>Quick Turnaround time</span></li>
            <li><span>No Minimum Order Quanity</span></li>
            <li><span>Unlimited options with design process</span></li>
            <li><span>Personalised names and numbers</span></li>
          </ul>
        </div>
        <div className="content_sub_heading">
          <span>Unique Features</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>All neck line options available</span></li>
            <li><span>Shorts include elastic waist with drawstring</span></li>
          </ul>
        </div>
        <div className="redirect_button">
          <span>Transform your club's Jerseys!</span>
          <Link to="/contact"><button>Get in Touch</button></Link>
        </div>

        {/*Training*/}
        <div className="content_heading">
          <span>TRAINING & WARM UP</span>
        </div>
        <hr />
        <div className="content_tagline">
          <div className="bar"></div>
          <span>Train like a champion</span>
        </div>
        <div className="content_sub_heading">
          <span>Reversible Jerseys</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>Sampling prior to production</span></li>
            <li><span>Quick Turnaround time</span></li>
            <li><span>No Minimum Order Quanity</span></li>
            <li><span>Unlimited options with design process</span></li>
          </ul>
        </div>
        <div className="content_sub_heading">
          <span>Unique Features</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>All neck line options available</span></li>
            <li><span>Shorts include elastic waist with drawstring</span></li>
            <li><span>Various coloured bibs available</span></li>
          </ul>
        </div>
        <div className="redirect_button">
          <span>Train hard to play hard!</span>
          <Link to="/contact"><button>Get in Touch</button></Link>
        </div>
        <div className="content_heading">
          <span>OFF-COURT</span>
        </div>
        <hr />
        <div className="content_tagline">
          <div className="bar"></div>
          <span>Fully customised gear to always look your best</span>
        </div>
        <div className="content_text two_content">
          <div className="content">
            <div className="content_sub_heading">
              <span>Summer</span>
              <span className='subheading_tagline'>Polos and shorts</span>
            </div>
            <ul>
              <li><span>Sampling prior to production</span></li>
              <li><span>Quick Turnaround time</span></li>
              <li><span>No Minimum Order Quanity</span></li>
              <li><span>Unlimited design options</span></li>
              <li><span>Any print method available</span></li>
            </ul>
          </div>
          <div className="content">
            <div className="content_sub_heading">
              <span>Winter</span>
              <span className='subheading_tagline'>Hoodies and tracksuits</span>
            </div>
            <ul>
              <li><span>Sampling prior to production</span></li>
              <li><span>Quick Turnaround time</span></li>
              <li><span>No Minimum Order Quanity</span></li>
              <li><span>Unlimited design options</span></li>
              <li><span>Any print method available</span></li>
            </ul>
          </div>
        </div>
              <div className="redirect_button">
                <span>Off-field apparel at great pricing!</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
              </div>
              <div className="content_heading">
                <span>FOR THE FANS</span>
              </div>
              <hr />
              <div className="content_tagline">
                <div className="bar"></div>
                  <span>Show your support</span>
                </div>
                <div className="content_text">
                  <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>Minimum Order Quanity</span></li>
                    <li><span>Unlimited options for design</span></li>
                  </ul>
                </div>
                <div className="redirect_button">
                  <span>Fully customised merchendise!</span>
                  <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
            </div>
    </div>
  )
}

export default AFL
