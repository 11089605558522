import React from 'react'
import landscape_banner from "../Components/Assets/headwearBanner.jpg"
import potrait_banner from "../Components/Assets/headwearBanner_Mobile.jpg"
import { Link } from 'react-router-dom'
import Hero from '../Components/Hero/Hero'

const Headwear = () => {
  return (
    <div>
      <Hero landscape_image = {landscape_banner} potrait_image = {potrait_banner}/>
      <div className="productContent">
        {/*Redirect button*/}
        <div className="button_container">
          <button>Get your own custom Tshirts!</button>
        </div>
        {/*On-Court*/}
        <div className="content_heading">
          <span>BASKETBALL CAPS</span>
        </div>
        <hr />
        <div className="content_tagline">
          <div className="bar"></div>
          <span>Add your brand, club or company logos</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>Many options available</span></li>
            <li><span>Sampling prior to production</span></li>
            <li><span>Quick Turnaround time</span></li>
            <li><span>No Minimum Order Quanity</span></li>
            <li><span>Unlimited options with design process</span></li>
            </ul>
        </div>
        <div className="content_sub_heading">
          <span>Good for</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>Clothing brands</span></li>
            <li><span>Uniforms</span></li>
            <li><span>Sports Clubs</span></li>
            <li><span>Anything else!</span></li>
          </ul>
        </div>
        <div className="redirect_button">
          <span>Your logos, your choice</span>
          <Link to="/contact"><button>Get in Touch</button></Link>
        </div>

        {/*Training*/}
        <div className="content_heading">
          <span>SNAPBACK CAPS</span>
        </div>
        <hr />
        <div className="content_tagline">
          <div className="bar"></div>
          <span>Add your brand, club or company logos</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>Many options available</span></li>
            <li><span>Sampling prior to production</span></li>
            <li><span>Quick Turnaround time</span></li>
            <li><span>Minimum Order Quanity</span></li>
            <li><span>Unlimited options with design process</span></li>
          </ul>
        </div>
        <div className="content_sub_heading">
          <span>Good for</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>Clothing brands</span></li>
            <li><span>Uniforms</span></li>
            <li><span>Sports Clubs</span></li>
            <li><span>Anything else!</span></li>
          </ul>
        </div>
        <div className="redirect_button">
          <span>Great pricing on hats today!</span>
          <Link to="/contact"><button>Get in Touch</button></Link>
        </div>
        <div className="content_heading">
          <span>BEANIES</span>
        </div>
        <hr />
        <div className="content_tagline">
          <div className="bar"></div>
          <span>Keep your head warm in winter</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>No, you don't have to get the pom pom!</span></li>
            <li><span>Sampling prior to production</span></li>
            <li><span>Quick Turnaround time</span></li>
            <li><span>Minimum Order Quanity</span></li>
            <li><span>Unlimited options with design process</span></li>
          </ul>
        </div>
        <div className="content_sub_heading">
          <span>Good For</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>Clothing brands</span></li>
            <li><span>Sports Clubs</span></li>
            <li><span>Anything else!</span></li>
          </ul>
        </div>
        <div className="redirect_button">
          <span>Fully customised beanies</span>
          <Link to="/contact"><button>Get in Touch</button></Link>
        </div>
        <div className="content_heading">
          <span>BUCKET HATS</span>
        </div>
        <hr />
        <div className="content_tagline">
          <div className="bar"></div>
          <span>Add your brand, club or company logos</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>No, you don't have to get the pom pom!</span></li>
            <li><span>Sampling prior to production</span></li>
            <li><span>Quick Turnaround time</span></li>
            <li><span>Minimum Order Quanity</span></li>
            <li><span>Unlimited design options</span></li>
          </ul>
        </div>
        <div className="content_sub_heading">
          <span>Good For</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>Clothing brands</span></li>
            <li><span>School Uniforms</span></li>
            <li><span>Sports Clubs</span></li>
            <li><span>Anything else!</span></li>
          </ul>
        </div>
        <div className="redirect_button">
          <span>Get a quote on custom bucket hats!</span>
          <Link to="/contact"><button>Get in Touch</button></Link>
        </div>
        <div className="content_heading">
          <span>SCHOOL HEADWEAR</span>
        </div>
        <hr />
        <div className="content_tagline">
          <div className="bar"></div>
          <span>Winter tees!</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>No, you don't have to get the pom pom!</span></li>
            <li><span>Sampling prior to production</span></li>
            <li><span>Quick Turnaround time</span></li>
            <li><span>minimum Order Quanity</span></li>
            <li><span>Unlimited options with design process</span></li>
          </ul>
        </div>
        <div className="content_sub_heading">
            <span>Good For</span>
        </div>
        <div className="content_text">
          <ul>
            <li><span>School Uniforms</span></li>
            <li><span>Clothing brands</span></li>
            <li><span>Anything else!</span></li>
          </ul>
        </div>
        <div className="redirect_button">
          <span>Full range of school heawear option!</span>
          <Link to="/contact"><button>Get in Touch</button></Link>
        </div>
      </div>
    </div>
  )
}

export default Headwear
