import React from 'react'
import landscape_banner from "../Components/Assets/netballBanner.jpg"
import potrait_banner from "../Components/Assets/netballBanner_Mobile.jpg"
import { Link } from 'react-router-dom'
import Hero from '../Components/Hero/Hero'

const Netball = () => {
  return (
    <div>
        <Hero landscape_image = {landscape_banner} potrait_image = {potrait_banner}/>
            <div className="productContent">
                {/*Redirect button*/}
                <div className="button_container">
                    <button>Get your own custom Tshirts!</button>
                </div>

                {/*On-Court*/}
                <div className="content_heading">
                    <span>DRESSES & BIBS</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>It's more than a game</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="content_sub_heading">
                <span>Unique Features</span>
                </div>
                <div className="content_text">
                  <ul>
                    <li><span>All neck line options available</span></li>
                    <li><span>All coloured bibs available</span></li>
                    <li><span>Choose between bibs or patches</span></li>
                  </ul>
                </div>
                <div className="redirect_button">
                    <span>Transform your club's uniform</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>

                {/*Training*/}
                <div className="content_heading">
                    <span>POLO'S & SKIRTS</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>If you don't like dressess!</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Polo's & skirts at great pricing!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>HOODIES & TRACKSUITS</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>Show your position</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                        <li><span>Any print method available</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Fully customised gear!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>FOR THE FANS</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>Show your support!</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>Minimum Order Quanity Required</span></li>
                        <li><span>Unlimited design options</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Make sure your fans look great!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
            </div>
    </div>
  )
}

export default Netball
