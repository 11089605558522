import React from "react";
import './Products.css';
import { Link } from "react-router-dom";
import hoodie from '../Assets/hoodie_1.jpg';
import basketball from '../Assets/basketball_3.jpg';
import Tshirt from '../Assets/Tshirt_3.jpg';
import Soccer from '../Assets/Soccer_4.jpg';
import polotops from '../Assets/Polo_4.jpg';
import cricket from '../Assets/cricket_1.jpg';
import Headwear from '../Assets/Headwear_3.jpg';
import AFL from '../Assets/AFL_1.jpg';
import school_uniform from '../Assets/school_uniform_1.jpg';
import Netball from '../Assets/Netball_2.jpg';


const Products = () => {
    return(
        <div className="products">
           <div className="product_heading">
            <div className="product_title">
                <h1>OUR <span>PRODUCTS</span></h1>
            </div>
            <hr className="mainHr"/>
            <div className="product_tagline">
                <span>Crafting quality sportswear and apparel for every passion.</span>
            </div>
           </div>
           <div className="product_card_container">
            <div className="section">
                <Link to = "/hoodies">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={hoodie} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>Hoodie</span>
                    </div>
                </Link>
                <Link to = "/basketball">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={basketball} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>Basketball</span>
                    </div>
                </Link>
                <Link to = "/tshirt">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={Tshirt} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>Tshirt</span>
                    </div>
                </Link>
                <Link to = "/soccer">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={Soccer} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>Soccer</span>
                    </div>
                </Link>
                <Link to = "/polo">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={polotops} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>Polo Tops</span>
                    </div>
                </Link> 
                
            </div>
            <div className="section">
            <Link to = "/cricket">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={cricket} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>Cricket</span>
                    </div>
                </Link> 
                <Link to = "/headwear">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={Headwear} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>Headwear</span>
                    </div>
                </Link> 
                <Link to = "/afl">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={AFL} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>AFL</span>
                    </div>
                </Link> 
                <Link to = "/school">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={school_uniform} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>School Uniforms</span>
                    </div>
                </Link> 
                <Link to = "/netball">
                    <div className="product_card">
                        <div className="card_image">
                            <img src={Netball} alt="" />
                            <div className="shadow"></div>
                        </div>
                        <span>Netball</span>
                    </div>
                </Link> 
            </div>
           </div>
        </div>
    );
};

export default Products;