import React from 'react'
import { Accordion } from "rsuite";
import "./styles/price.css";
import priceBeatData from "../Components/Assets/priceBeatData"

const Price = () => {
  return (
    <div className='price'>
      <Accordion defaultActiveKey={1} bordered>
            {Object.entries(priceBeatData).map(([key, priceBeatData], index) => (
                <Accordion.Panel header={priceBeatData.question} eventKey={index + 1} key={key}>
                    {priceBeatData.answer}
                </Accordion.Panel>
            ))}
        </Accordion>
    </div>
  )
}

export default Price
