import React from 'react'
import landscape_banner from "../Components/Assets/schoolBanner.jpg"
import potrait_banner from "../Components/Assets/schoolBanner_Mobile.jpg"
import { Link } from 'react-router-dom'
import Hero from '../Components/Hero/Hero'

const SchoolUniform = () => {
  return (
    <div>
      <Hero landscape_image = {landscape_banner} potrait_image = {potrait_banner}/>
            <div className="productContent">
                {/*Redirect button*/}
                <div className="button_container">
                    <button>Get your own custom Tshirts!</button>
                </div>

                {/*On-Court*/}
                <div className="content_heading">
                    <span>POLO TOPS</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>Add your brand, club or company logos</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Many options available!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>

                {/*Training*/}
                <div className="content_heading">
                    <span>SHIRTS</span>
                </div>
                <hr />
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Fully customised shirts</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>HOODIES & SWEATERS</span>
                </div>
                <hr />
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Great for winter terms!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>BOTTOMS</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>Shorts & skirts</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited design options</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Order your school bottoms today!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>FORMAL</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>For the classy look!</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Great pricing on school formal wear!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>DRESSES</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>All styles and sizes</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Fully customised dresses!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>HATS</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>Don't forget your hats!</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>Minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Great pricing on school formal wear!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
            </div>
    </div>
  )
}

export default SchoolUniform
