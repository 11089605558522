const priceBeatData = {
    question1: {
        question: "What is the Price Beat Guarantee?",
        answer: "Our Price Beat Guarantee ensures that you receive the best possible price for our custom garments and printing services. If you find a lower price from a competitor for the same quality and service, we will not only match it but also beat it."
    },
    question2: {
        question: "How does the Price Beat Guarantee work?",
        answer: "We are located in Adelaide, South Australia and Our factory is strategically located in India and Taiwan, known for its rich tradition in textile manufacturing. This location allows us to source high-quality materials and employ skilled artisans to create exceptional garments."
    },
    question3: {
        question: "What products and services are eligible for the Price Beat Guarantee?",
        answer: "The Price Beat Guarantee applies to all our custom garments and printing services, including Sportswear, bespoke suits, dresses, casual wear, corporate uniforms, and various printing methods such as screen printing, digital printing, and embroidery."
    },
    question4: {
        question: "Are there any exclusions to the Price Beat Guarantee?",
        answer: "The guarantee excludes clearance items, special promotions, bulk discounts from competitors, and offers that include additional services not comparable to ours."
    },
    question5: {
        question: "Can I use the Price Beat Guarantee for online competitors?",
        answer: "Yes, we accept quotes from both local and online competitors, provided the products and services offered are identical in quality and specifications."
    },
    question6: {
        question: "How do I submit a competitor's quote for the Price Beat Guarantee?",
        answer: "You can submit a competitor's quote via email : info@ozlooms.com.au , in-person at our store, or through the contact form on our website. Ensure the quote includes all necessary details such as product description, price, and date."
    },
    question7: {
        question: "What information do I need to provide to use the Price Beat Guarantee?",
        answer: "Provide a copy of the competitor's quote with detailed information on the product or service, including material specifications, design details, and pricing."
    },
    question8: {
        question:"How long does it take to verify and apply the Price Beat Guarantee?",
        answer: "We aim to verify and respond to your Price Beat Guarantee request within 1-2 business days."
    },
    question9: {
        question:"Is there a limit to how many times I can use the Price Beat Guarantee?",
        answer: "There is no limit to the number of times you can use the Price Beat Guarantee, as long as each request meets our criteria."
    },
    question10: {
        question: "Can I use the Price Beat Guarantee in conjunction with other discounts or promotions?",
        answer: "The Price Beat Guarantee cannot be combined with other discounts, promotions, or special offers. It applies solely to the base price comparison."
    },
    question11: {
        question: "Who can I contact if I have questions about the Price Beat Guarantee?",
        answer: "For any questions or assistance regarding the Price Beat Guarantee, please contact our customer support team via email, or through our website's contact form."
    },
    question12: {
        question: "Why do you offer a Price Beat Guarantee?",
        answer: "We believe in providing exceptional value to our customers. Our Price Beat Guarantee reflects our commitment to offering high-quality custom garments and services at competitive prices."
    },
    question13: {
        question: " What if I find a lower price after I have already placed an order?",
        answer: " If you find a lower price within 7 days of placing your order, contact us with the competitor's quote, and we will refund the difference."
    },
    question14: {
        question: "Can I schedule a visit to your factory?",
        answer: "Yes, we welcome visitors to our factory by appointment. Please contact us to schedule a visit and see our craftsmanship firsthand."
    },
};

export default priceBeatData;