import React from 'react'
import "./SustainableFashion.css"
import sf1 from "../Assets/sf1.png"
import sf2 from "../Assets/sf2.png"
const SustainableFashion = () => {
  return (
    <div className='sustain'>
        <div className="sustainHeading">
            <span className="heading"><span className='colorChange'>Sustainable</span> Fashion</span>
        </div>
        <div className="sustainContent">
            <section className='contentSection'> 
                <span className="sectionHeading">Eco-Friendly Fabrics</span>
                <span className='sectionTagline'>Offering a selection of sustainable and eco-friendly fabrics for environmentally conscious clients.</span>
                <button>Connect with us</button>
            </section>
            <section className='imgSection'>
                <img src={sf1} alt="" />
            </section>
        </div>
        <div className="sustainContent second">
            <section className='imgSection'>
                <img src={sf2} alt="" />
            </section>
            <section className='contentSection'> 
                <span className="sectionHeading">Sustainable Production Practices</span>
                <span className='sectionTagline'>Commitment to reducing our environmental impact through responsible production methods and waste reduction initiatives.</span>
                <button>Connect with us</button>
            </section>
        </div>
    </div>
  )
}

export default SustainableFashion
