import React from 'react'
import "./Promises.css"
import promise1 from "../Assets/promise1.png";
import promise2 from "../Assets/promise2.png";
import promise3 from "../Assets/promise3.png";

const Promises = () => {
  return (
    <div className='promise'>
        <div className="promiseHeading">
            <span className="promiseTitle">OUR PROMISE</span>
            <span className='promiseTagline'>At OZLOOMS we are dedicated to exceeding your expectations. Our promise to you includes:
            </span>
        </div>
        <div className="promiseContent">
            <div className="card">
                <div className="cardImage">
                    <img src={promise1} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardTitle">Uncompromising quality:</span>
                    <span className="cardTagline"> We use only the highest quality fabrics and materials.</span>
                </div>
            </div>
            <div className="card">
                <div className="cardImage">
                    <img src={promise2} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardTitle">Perfect Fit:</span>
                    <span className="cardTagline">We offer personalized attention and exceptional service throughout the entire process.</span>
                </div>
            </div>
            <div className="card">
                <div className="cardImage">
                    <img src={promise3} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardTitle">Personalized Service:</span>
                    <span className="cardTagline"> We use only the highest quality fabrics and materials.</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Promises;